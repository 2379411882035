<template>
  <div>
    <div class="super">
      <div class="holder">
        <div>
          <Header />

          <div class="faq_holder container-fluid">
            <div class="faq_header">
              <h1>Privacy Policy</h1>
              <p>
                Persons accessing the Shelta website subject themselves to and
                agree to SHELTA PANACEA LTD terms conditions and privacy policy,
                as set out below.
              </p>
              <p>
                We respect your privacy and take it very seriously. We are
                SHELTA PANACEA LTD, and this is our plan of action for the
                protection of your personal information. It describes how
                we collect, use, disclose, and store your personal information.
              </p>
            </div>
            <div class="faq_wrap">
              <div class="qa">
                <h3 class="question">
                  Application
                </h3>
                <h6 class="answer">
                  This policy applies to you when you visit our website, use our
                  application, or order our products or services as a customer.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Personal information
                </h3>
                <h6 class="answer">
                  Your personal information includes information we collect:
                  <p>
                    • automatically when you visit our website or use our
                    application;
                  </p>
                  <p>• accept on registration or submission;</p>
                  <p>• get through an order; or</p>
                  <p>• you provide voluntarily.</p>
                  It excludes anonymous, de-identified, statistical, and public
                  information. Obligations. You may only send us your own
                  personal information or someone else’s personal information if
                  you have their permission to do so.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Obligations.
                </h3>
                <h6 class="answer">
                  You may only send us your own personal information or someone
                  else’s personal information if you have their permission to do
                  so.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Acceptance and changes
                </h3>
                <h6 class="answer">
                  <span style="font-weight: bold;">Acceptance.</span> You may
                  not order any of our products or services if you do not accept
                  this policy or any changes to it.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Changes.</span> We may change
                  this policy at any time and will notify you of the changes on
                  our website, through our application, or by email. The changed
                  policy applies to you if you continue using our website, our
                  application, or our products or services following the
                  notification.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  INFORMATION COLLECTED AUTOMATICALLY
                </h3>
                <h6 class="answer">
                  <span style="font-weight: bold;">THROUGH WEBSITE.</span> When
                  you visit our website, we automatically collect your Internet
                  usage information (including your IP address, browser details,
                  and usage information) through your browser, which we may use
                  to display our website correctly, track your activities on it,
                  or for other necessary purposes.
                </h6>
                <h6>
                  <span style="font-weight: bold;">THROUGH APPLICATION.</span>
                  When you use our application, we automatically collect
                  your application usage information (including your operating
                  system version and other relevant system information) through
                  analytics software and other technologies, which we may use to
                  optimise our offerings to you.
                </h6>
                <h6>
                  <span style="font-weight: bold;"
                    >ON REGISTRATION OR SUBMISSION</span
                  >. We may ask you to provide us with certain identifying
                  information (including your first name, surname, and email
                  address) when you register on or otherwise submit information
                  through our website or application for the first time.
                </h6>
                <h6>
                  <span style="font-weight: bold;">THROUGH AN ORDER. </span>We
                  ask you to provide us certain products or services
                  information when you order our products or services.
                </h6>
                <h6>
                  <span style="font-weight: bold;">VOLUNTARILY</span>We may ask
                  you to provide us certain optional information on a voluntary
                  basis.
                </h6>
                <h6>
                  <span style="font-weight: bold;">CONSENT</span> We will get
                  your consent to collect your personal information in
                  accordance with applicable laws when you provide us with it.
                </h6>
                <h6>
                  <span style="font-weight: bold;">PURPOSE FOR COLLECTION.</span
                  >We may process your information that you provide to us for
                  the purposes that you indicated when you agreed to provide it
                  to us. Processing includes gathering your personal
                  information, disclosing it, and combining it with other
                  personal information.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  HOW WE USE YOUR INFORMATION
                </h3>
                <h6 class="answer">
                  We may process your personal information to fulfil our
                  obligations to you.
                </h6>
                <h6>
                  <span style="font-weight: bold;">COOKIES</span>
                  We may place small text files on your device when you visit
                  our website that allow us to provide you with a personalised
                  experience by associating your personal information with your
                  device. This lets us remember your preferences, allow third
                  parties to provide services to you, and otherwise serve useful
                  purposes for you. Your internet browser generally accepts them
                  automatically, but you can often change this setting or delete
                  them manually. However, we won’t be able to provide you with
                  access to certain aspects of our website where cookies are
                  necessary if you do so. We have no access to or control over
                  any cookies that our business partners use on our website and
                  they have their own privacy policies that govern them.
                </h6>
                <h6>
                  <span style="font-weight: bold;">PROMOTIONAL MESSAGES.</span>
                  MESSAGES. We may use your identifying information to send you
                  administrative and update messages about our website or
                  application.
                </h6>
                <h6>
                  <span style="font-weight: bold;">TARGETED CONTENT.</span>
                  We may use your personal information to provide you with
                  targeted content through our website or application in a
                  completely automated process.
                </h6>
                <h6>
                  <span style="font-weight: bold;">RECORDING CALLS.</span>
                  As part of Shelta Quality assurance policies; We may monitor
                  and record any telephone calls that you make to us, unless you
                  specifically request us not to.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  INFORMATION DISCLOSURE
                </h3>
                <h6 class="answer">
                  <span style="font-weight: bold;">Sharing.</span> We may share
                  your personal information with third parties for the purposes
                  of fulfilling our obligations to you, including with:
                </h6>
                <h6 class="answer">
                  <p>
                    • other companies or divisions within our group;
                  </p>
                  <p>
                    • our contractors who help us meet our obligations to you,
                    including those that help us understand your personal
                    information; or
                  </p>
                  <p>• get through an order; or</p>
                  <p>• third party as required by applicable law.</p>
                </h6>
                <h6>
                  <span style="font-weight: bold;">However</span>
                  We will require anyone that we share your personal information
                  with to honour this policy whenever possible in terms of
                  applicable law.
                </h6>
                <h6>
                  <span style="font-weight: bold;">Mandatory disclosure. </span>
                  We may disclose personal information to third parties if
                  required for legal reasons, such as if required by the
                  relevant authorities or a court order.
                </h6>
                <h6>
                  <span style="font-weight: bold;">Marketing purposes.</span>
                  We may disclose aggregate statistical information that we
                  derive from you and other people’s personal information to our
                  advertisers or business partners.
                </h6>
                <h6>
                  <span style="font-weight: bold;">Personnel.</span>
                  We may need to disclose personal information to our personnel
                  to do their jobs, but will not do so unnecessarily.
                </h6>
                <h6>
                  <span style="font-weight: bold;">Change of ownership.</span>
                  We may assign our rights to the personal information we
                  process to our new owners if our ownership changes for any
                  reason.
                </h6>
              </div>

              <div class="qa">
                <h3 class="question">
                  SECURITY
                </h3>
                <h6 class="answer">
                  We take the security of personal information very seriously
                  and always do our best to comply with applicable data
                  protection laws. Our hosting company will host our website in
                  a secure server environment that uses a firewall and other
                  advanced security measures to prevent interference or access
                  from intruders. We implement disaster recovery procedures
                  where appropriate.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  INFORMATION STORAGE
                </h3>
                <h6 class="answer">
                  <span style="font-weight: bold;">Access.</span>
                  You may ask us to provide you with a description of the
                  personal information that we hold on you, correct or update it
                  or delete it (provided that we do not need it to fulfil our
                  obligations to you or applicable law does not require us to
                  keep it). We will take all reasonable steps to verify your
                  identity before doing so. We may charge a fee to recoup our
                  costs associated with this request if there is a cost to us
                  associated with it and provided that applicable law allows us
                  to do so. Please contact us with your name and any other
                  information needed to identify you correctly if you want
                  access to information or want us to correct, update or delete
                  it.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Accuracy</span>
                  We will do our best to keep your personal information that we
                  collect accurate, complete, and up to date.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Participation</span>
                  We may ask you to update your personal information from time
                  to time, but will take steps to verify your identity to
                  prevent unauthorised access when doing so.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Retention</span>
                  We will only keep your personal information for as long as is
                  necessary to fulfil our obligations to you, unless you have
                  given us permission to keep it longer or we are otherwise
                  legally allowed to do so.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Transfer</span>
                  We may transfer your personal information outside of the
                  country in which it was collected to a foreign country. You
                  consent to us processing your personal information in a
                  foreign country with less stringent data protection laws than
                  the country in which it was collected.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Updating or removing. </span>
                  <p>• through our website or application; or</p>
                  <p>• by contacting us.</p>
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Limitation</span>
                  We are not responsible for anyone else’s privacy policies or
                  practices.
                </h6>
                <h6 class="answer">
                  <span style="font-weight: bold;">Enquiries</span>
                  Please contact us through our official email
                  hello@myshelta.com if you have any questions about this
                  privacy policy or how we handle your personal information.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import $ from "jquery";
import { Service } from "../store/service";
const Api = new Service();

export default {
  // props: ["year", "month", "date", "hour", "minutes", "seconds", "milliseconds"],
  // props: ["2021", "2", "28", "10", "8", "9", "12"],

  name: "PrivacyPolicy",
  components: {
    Header,
    FooterMain
  },

  data() {
    return {};
  },

  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;

// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.faq_holder {
  margin-top: 8rem;
  // margin: 10px;
  // padding-left: 15rem;
  // padding-right: 15rem;
  // text-align: left;
}
.faq_header {
  h1 {
    font-family: Gotham;
    font-weight: bold;
    font-style: normal;
    font-size: 40px;
    line-height: 60px;
  }
  p {
    font-family: Gotham;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 29px;
  }
}

.qa {
  margin: 20px 0;
  h3 {
    font-family: Gotham;
    font-weight: 800;
    font-style: normal;
    font-size: 23px;
    line-height: 40px;
  }
  h6 {
    font-family: Gotham;
    font-weight: 200;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media only screen and (max-width: 599px) {
  .faq_holder {
    text-align: left;
    // margin-top: 6rem;
    // padding-left: 15px;
    // padding-right: 15px;
  }
  .faq_header {
    h1 {
      font-size: 25px;
    }
    p {
      font-size: 18px;
    }
  }
  .qa {
    h3 {
      font-family: Gotham;
      font-weight: 800;
      font-style: normal;
      font-size: 20px;
      line-height: 30px;
    }
    h6 {
      font-family: Gotham;
      font-weight: 200;
      font-style: normal;
      font-size: 17px;
      line-height: 25px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .count-down-child {
    padding: 0 10rem;
  }
  .faq_holder {
    padding-left: 15rem;
    padding-right: 15rem;
    text-align: left;
  }
}
</style>
